import Mobilenav from '../Mobilenav/Mobilenav';
import Nav from '../Nav/Nav';
import './Navigation.sass'

const Navigation = () => {
    return ( 
        <main className="Navigation">
            <div className="parent">
                <div className="mobilenav">
                    <Mobilenav />
                </div>
                <div className="nav">
                    <Nav />
                </div>
            </div>
        </main>
     );
}
 
export default Navigation;