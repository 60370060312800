import './Compb.sass'
import { BsTruck, BsApple } from 'react-icons/bs';
import { FaDropbox } from 'react-icons/fa';
import { CgCreditCard } from 'react-icons/cg';
import { BiCurrentLocation } from 'react-icons/bi';

const Compb = () => {
    return ( 
        <main className="Compb">
            <div className="parent">
                <div className="card">
                    <div className="left">
                        <BsTruck className='icona'/>
                        <BsTruck className='iconb'/>
                    </div>
                    <div className="right">
                        <p className='head'>FAST FOOD DELIVERY</p>
                        <p>Lorem, ipsum dolor sit adipisicing.</p>
                    </div>
                </div>
                <div className="card">
                    <div className="left">
                        <BiCurrentLocation className='icona'/>
                        <BiCurrentLocation className='iconb'/>
                    </div>
                    <div className="right">
                        <p className='head'>USE ORDER TRACKING</p>
                        <p>Lorem, ipsum dolor sit amet consectetur.</p>
                    </div>
                </div>
                <div className="card">
                    <div className="left">
                        <FaDropbox className='icona'/>
                        <FaDropbox className='iconb'/>
                    </div>
                    <div className="right">
                        <p className='head'>BEST FOOD QUALITY</p>
                        <p>Lorem, ipsum dolor sit amet consectetur.</p>
                    </div>
                </div>
                <div className="card">
                    <div className="left">
                        <CgCreditCard className='icona'/>
                        <CgCreditCard className='iconb'/>
                    </div>
                    <div className="right" style={{borderRight: 'none'}}>
                        <p className='head'>EASY RETURN POLICY</p>
                        <p>Lorem, ipsum dolor sit amet conse.</p>
                    </div>
                </div>
            </div>

        </main>
     );
}
 
export default Compb;
