import './Compd.sass'
import { BsStarFill } from 'react-icons/bs'
import A from '../../Images/i.jpg'
import B from '../../Images/j.jpg'

const Compd = () => {
    return ( 
        <main className="Compd">
            <h2>Weekly Deals</h2>
            <div className="parent">
                <div className="container">
                    <div className="same">
                        <div className="a">
                            <img src={A} alt="" />
                        </div>
                        <div className="b">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                            <div className="stars">
                                <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                            </div>
                            <p className="price">$1,150.00</p>
                            <p className="text">hello</p>
                            <div className="calendar">
                                <p className='box'>
                                    <p className='top'>13</p>
                                    <p className='btm'>Sunday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>14</p>
                                    <p className='btm'>Monday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>15</p>
                                    <p className='btm'>Tuesday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>18</p>
                                    <p className='btm'>Thurday</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="same">
                        <div className="a">
                            <img src={B} alt="" />
                        </div>
                        <div className="b">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                            <div className="stars">
                                <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                            </div>
                            <p className="price">$1,150.00</p>
                            <p className="text">hello</p>
                            <div className="calendar">
                                <p className='box'>
                                    <p className='top'>13</p>
                                    <p className='btm'>Sunday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>14</p>
                                    <p className='btm'>Monday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>15</p>
                                    <p className='btm'>Tuesday</p>
                                </p>
                                <p className='box'>
                                    <p className='top'>18</p>
                                    <p className='btm'>Thurday</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
     );
}
 
export default Compd;
