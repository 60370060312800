import { AccountCircle, Clear, Close, Menu, ShoppingCart } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import './Mobilenav.sass'

const Mobilenav = () => {
    const [opened, isOpened] = useState(false)
    const menuref = useRef()
    const changeicons = () => {
        isOpened(!opened)
        
    }
    useEffect(
        ()=>{
        if(opened===true){
            menuref.current.style.transform='translateX(0)'
        }
        else{
            menuref.current.style.transform='translateX(200vw)'
        }
        },[opened]
    )
    return ( 
        <main className="Mobilenav">
            <div className="parent">
                <div className="navcover">
                    <div className="top">
                        <div className="logo">
                            <div className="name">
                                <span className='haz'>Hazzies</span>
                                <span className='cui'>Cuisine</span>
                            </div>
                        </div>
                        <div className="icons">
                            <ShoppingCart />
                            <AccountCircle />
                        </div>
                    </div>
                    <div className="input">
                        <div id="input">
                            <input type="text" placeholder='Search Favourite Food...'/>
                            <button>SEARCH</button>
                        </div>
                    </div>
                    <div className="menu">
                        <p>MENU</p>
                        {opened
                        ?
                        (
                            <Clear onClick={changeicons} style={{cursor: 'pointer'}}/>
                        )
                        :
                        (
                            <Menu onClick={changeicons} style={{cursor: 'pointer'}}/>
                        )
                    }
                    </div>
                </div>
                <div className="menus" ref={menuref}>
                    <ul>    
                        <li><a href="">Home</a></li>
                        <li><a href="">About</a></li>
                        <li><a href="">Deals </a></li>
                        <li><a href="">Infomation</a></li>
                        <li><a href="">Blog</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Help</a></li>
                    </ul>
                </div>
            </div>
            
        </main>
     );
}
 
export default Mobilenav;