import './Compc.sass'
import { BsChevronDoubleRight } from 'react-icons/bs'
import A from '../../Images/cms1.jpg'
import B from '../../Images/cms2.jpg'
import C from '../../Images/cms3.jpg'
import D from '../../Images/cms4.jpg'

const Compc = () => {
    return ( 
        <main className="Compc">
            <h2>Newly Added</h2>
            <div className="parent">
                <div className="box">
                    <img src={A} alt="" />
                    <div className="last">
                        <p>Sweet Delicious Food</p>
                        <BsChevronDoubleRight />
                    </div>
                </div>
                
                <div className="box">
                    <img src={B} alt="" />
                    <div className="last">
                        <p>Sweet Delicious Food</p>
                        <BsChevronDoubleRight />
                    </div>
                </div>
                
                <div className="box">
                    <img src={C} alt="" />
                    <div className="last">
                        <p>Sweet Delicious Food</p>
                        <BsChevronDoubleRight />
                    </div>
                </div>
                
                <div className="box">
                    <img src={D} alt="" />
                    <div className="last">
                        <p>Sweet Delicious Food</p>
                        <BsChevronDoubleRight />
                    </div>
                </div>
                
            </div>
        </main>
     );
}
 
export default Compc;
