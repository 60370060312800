import Compa from "../../Components/Compa/Compa";
import Compb from "../../Components/Compb/Compb";
import Compc from "../../Components/Compc/Compc";
import Compd from "../../Components/Compd/Compd";
import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
const Home = () => {
    return ( 
        <main className="Home">
            <Navigation />
            <Compb />
            <Compa />
            <Compc />
            <Compd /> 
            <Footer />
        </main>
     );
}
export default Home;