import './Compa.sass'
import { BsHeartFill, BsStarFill, BsStarHalf, BsCart, BsShop, BsEyeFill } from 'react-icons/bs'
import Imga from '../../Images/a.jpg'
import Imgb from '../../Images/b.jpg'
import Imgc from '../../Images/c.jpg'
import Imgd from '../../Images/d.jpg'
import Imge from '../../Images/e.jpg'
import Imgf from '../../Images/f.jpg'
import Imgg from '../../Images/g.jpg'
import Imgh from '../../Images/h.jpg'
const infos = [
    {
        text: 'Lorem ipsu sit  adipisicing elit. Earum  eligendi et?',
        price: '$570.00',
        imga: Imga,
        imgb: Imgb
    },
    {
        text: 'Lorem share signing sit  adipisicing elit. eligendi et?',
        price: '$200.00',
        imga: Imgc,
        imgb: Imgd
    },
    {
        text: 'Lorem adipisicing.Sptatum eligendi et?',
        price: '$320.00',
        imga: Imge,
        imgb: Imgf
    },
    {
        text: 'Lorem share deligent sit  adipisicing.Eligendi share etsit?',
        price: '$480.00',
        imga: Imgg,
        imgb: Imgh
    }
    // ,
    // {
    //     text: 'Lorem ipsum dolor sit  adipisicing elit. Earum  eligendi et?',
    //     price: '$570.00',
    //     imga: Imga,
    //     imgb: Imgb
    // }
]
const Compa = () => {
    return ( 
        <main className="Compa">
            <h2>Latest Dishes</h2>
            <div className="parent">
                {infos.map((info)=>(
                    <div className="card">
                        <a href="google.com"></a>
                        <div className="top">
                            <div className="like">
                                <div className="stars">
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarHalf />
                                </div>
                                <BsHeartFill className='heart' />
                            </div>
                            <div className="photo">
                                <div className="img1"><img src={info.imga} alt="" /></div>
                                <div className="img2"><img src={info.imgb} alt="" /></div>
                                <div className="links">
                                    <BsCart className='icon icona' />
                                    <BsShop className='icon iconb' />
                                    <BsEyeFill className='icon  iconc' />
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <p className='text'>{info.text}</p>
                            <p className='price'>{info.price}</p>
                        </div>
                    </div>
                ))}
            </div>
        </main>
     );
}
export default Compa;
