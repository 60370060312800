import { AccountCircle, ShoppingCart } from '@mui/icons-material';
import './Nav.sass'

const Nav = () => {
    return ( 
        <main className="Nav">
            <div className="parent">
                <div className="search">
                    <div className="logo">
                        <div className="name">
                            <span className='haz'>Hazzies</span>
                            <span className='cui'>Cuisine</span>
                        </div>
                    </div>
                    <div className="input">
                        <div id="input">
                            <input type="text" placeholder='Search Favourite Food...'/>
                            <button>SEARCH</button>
                        </div>
                    </div>
                    <div className="others">
                        <div className="cart">
                            <ShoppingCart className='icon'/>
                            <p className='wrapper'>
                                <p className='title'>MY CART</p>
                                <p className='subtitle1'>$0.00 <span>0</span></p>
                            </p>
                        </div>
                        <div className="user">
                            <AccountCircle className='icon'/>
                            <p className='wrapper'>
                                <p className='title'>MY ACCOUNT</p>
                                <p className='subtitle2'>LOGIN / SIGN UP</p>
                            </p>
                        </div>
                        <div className="signup"></div>
                    </div>
                </div>
                <div className="links">
                    <ul>
                        <li><a href="">Home</a></li>
                        <li><a href="">About</a></li>
                        <li><a href="">Deals </a></li>
                        <li><a href="">Information</a></li>
                        <li><a href="">Blog</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Help</a></li>
                    </ul>


                </div>

            </div>
        </main>
     );
}
 
export default Nav;