import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import './Footer.sass'


const Footer = () => {
    return ( 
        <main className="Footer">
            <div className="parent">
                <div className="one">
                    <p className="header">
                        Pages
                    </p>
                    <ul>
                        <li><a href="">Home</a></li>
                        <li><a href="">About</a></li>
                        <li><a href="">Deals </a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Help</a></li>
                    </ul>
                </div>
                <div className="two">
                <p className="header">
                        About
                    </p>
                    <ul>
                        <li><a href="">Website</a></li>
                        <li><a href="">Terms & Conditions</a></li>
                        <li><a href="">Contact</a></li>
                    </ul>
                </div>
                <div className="four">
                    <div className="header">Follow us</div>
                    <div className="socials">
                        <Facebook />
                        <Twitter />
                        <Instagram />
                        <YouTube />
                    </div>
                </div>
                <div className="three">
                    <div className="header">Newsletter</div>
                    <p>Enter your email to be updated on whats going on and if you dont want to keep getting emails from us you can easily unsubscribe via any of our emails</p>
                    <div className="input-btn">
                        <input type="text" />
                        <button>SEND</button>
                    </div>
                    <p className='agree'>By Cliking 'SEND' you have agreed to the Terms and Conditions as well as you have read the Privacy Policy of this Company</p>
                </div>
                
            </div>
        </main>
     );
}
export default Footer;
